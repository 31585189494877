<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Assign {{ groupName.toLowerCase() }} {{ is_president ? 'president' : 'supervisor' }}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row>

            <v-col cols="12" v-if="is_president">
              <span class="mb-3 font-size-lg" v-if="is_president">
                <b>Club First President: </b> {{group.president_one || 'N/A'}}
              </span>
              <v-autocomplete
                  class="mt-3"
                  @change="selectPresident"
                  v-model="selectedPresidentOne"
                  outlined
                  dense
                  :items="newFirstPresidentUsers"
                  hide-selected
                  item-text="display_text"
                  item-value="id"
                  :search-input.sync="searchFirstPresident"
                  @input="group.president_one_id = $event !== null ? $event : ''"
                  :loading="isUserLoading"
                  clearable
                  chips
                  small-chips
                  deletable-chips
              >
                <template v-slot:label>
                  <span class="text-danger"></span> Search by name, email or CRN
                </template>
                <template v-slot:no-data>
                  <div class="no-data-auto-complete text-center">No data available </div>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.group.president_one_id.$error">This information is required</span>
            </v-col>

            <v-col cols="12" v-if="is_president">
               <span class="mb-3 font-size-lg" v-if="is_president">
                <b>Club Second President: </b> {{group.president_two || 'N/A'}}
              </span>
              <v-autocomplete
                  class="mt-3"
                  @change="selectPresident"
                  v-model="selectedPresidentTwo"
                  outlined
                  dense
                  :items="newSecondPresidentUsers"
                  hide-selected
                  item-text="display_text"
                  item-value="id"
                  :search-input.sync="searchSecondPresident"
                  @input="group.president_two_id = $event !== null ? $event : ''"
                  :loading="isUserLoading"
                  clearable
                  chips
                  small-chips
                  deletable-chips
              >
                <template v-slot:label>
                  <span class="text-danger"></span> Search by name, email or CRN
                </template>
                <template v-slot:no-data>
                  <div class="no-data-auto-complete text-center">No data available </div>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.group.president_one_id.$error">This information is required</span>
            </v-col>

            <v-col cols="12" v-if="!is_president">
               <span class="my-3 font-size-lg" v-if="!is_president">
                <b>Club Supervisor: </b>
              </span>
              <ul class="mb-3" v-if="group && group.supervisors.length > 0">
                <li class="mx-10" v-for="(supervisor, index) in group.supervisors" :key="index">
                  <v-chip class="my-1" close small @click:close="removeSupervisor(index)">{{supervisor || 'N/A'}}</v-chip>
                </li>
              </ul>
              <ul class="mb-3" v-if="group && group.supervisors.length == 0">
                <span class="text-center">No one supervisor is selected</span>
              </ul>

              <v-autocomplete
                  class="mt-3"
                  @change="selectSupervisor"
                  v-model="selectedSupervisor"
                  outlined
                  dense
                  :items="newSupervisorUsers"
                  hide-selected
                  item-text="display_text"
                  item-value="id"
                  :search-input.sync="searchSupervisor"
                  @input="selectedSupervisor = $event !== [] ? $event : ''"
                  :loading="isUserLoading"
                  clearable
                  multiple
                  chips
                  small-chips
                  deletable-chips
              >
                <template v-slot:label>
                  <span class="text-danger"></span> Search by name, email or CRN
                </template>
                <template v-slot:no-data>
                  <div class="no-data-auto-complete text-center">No data available </div>
                </template>
              </v-autocomplete>
              <span class="text-danger">** Select ECA coordinator, Student services officer and Student engagement officer</span>
              <br>
              <span class="text-danger" v-if="$v.group.supervisor_ids.$error">This information is required</span>
            </v-col>

            <v-col cols="12">
              <div class="text-right float-right">
                <v-btn
                    depressed
                    @click="closeDialog"
                    class="text-gray btn btn-standard
                                ">
                  Cancel
                </v-btn>
                <v-btn
                    depressed
                    :loading="isBusy"
                    @click="createOrUpdate"
                    v-if="group.supervisor_ids.length < 3"
                    class="text-white ml-2 btn btn-primary"
                >
                  Assign
                </v-btn>
              </div>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>

</template>
<script>
import {requiredIf} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import UserGroupService from "@/core/services/user/user-group/UserGroupService";

const user = new UserService();
const group = new UserGroupService();

export default {
  name: "AssignLeaderAndRepresented",
  validations: {
    group: {
      president_one_id: {required:requiredIf(function () {
        return this.is_president
        })
      },
      supervisor_ids: {required:requiredIf(function () {
        return !this.is_president
        })
      },
    }
  },
  data() {
    return {
      firstPresidentUsers: [],
      secondPresidentUsers: [],
      supervisorUsers: [],
      dialog: false,
      isBusy: false,
      isUserLoading: false,
      searchSupervisor: '',
      is_president: false,
      searchFirstPresident: '',
      searchSecondPresident: '',
      selectedSupervisor: [],
      selectedPresidentOne: null,
      selectedPresidentTwo: null,
      group: {
        president_one_id: '',
        president_two_id: '',
        supervisor_ids: [],
        president_one: '',
        president_two: '',
        supervisors: [],
      },

    };
  },
  computed: {
    newFirstPresidentUsers() {
      return this.firstPresidentUsers.map((user) => {
        user.display_text =
            user.full_name +
            " | " +
            user.personal_id +
            " | " +
            user.email || ''
        return user;
      });
    },
    newSecondPresidentUsers() {
      return this.secondPresidentUsers.map((user) => {
        user.display_text =
            user.full_name +
            " | " +
            user.personal_id +
            " | " +
            user.email || ''
        return user;
      });
    },
    newSupervisorUsers() {
      return this.supervisorUsers.map((user) => {
        user.display_text =
            user.full_name +
            " | " +
            user.personal_id +
            " | " +
            user.email || ''
        return user;
      });
    },
  },
  methods: {
    openDialog(userGroup, isLeader) {
      this.dialog = true;
      this.is_president = isLeader;
      this.group = userGroup;
      this.group.president_one = userGroup.president_one || '';
      this.group.president_two = userGroup.president_two || '';
    },

    selectPresident() {
      if(this.selectedPresidentOne && this.selectedPresidentOne && this.is_president){
        this.group.president_one_id = this.selectedPresidentOne || '';
      } else if(this.selectedPresidentTwo && this.selectedPresidentTwo && this.is_president){
        this.group.president_two_id = this.selectedPresidentTwo || '';
      }  else {
        this.group.president_one_id = '';
        this.group.president_two_id = '';
      }
    },

    selectSupervisor() {
      if(this.selectedSupervisor && this.selectedSupervisor.length > this.group.count_supervisors && !this.is_president) {
        this.selectedSupervisor.pop();
        this.$snotify.error("Maximum supervisor three users")
      }
    },

    closeDialog() {
      this.dialog = false;
      this.resetForm()
    },

    removeSupervisor(index) {

      this.$confirm({
        message: `Are you sure you want to delete?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.group.supervisor_ids.splice(index, 1);
            this.group.supervisors.splice(index, 1);
            this.isBusy = true;
            group
                .update(this.group.id, this.group)
                .then((response) => {
                  if(response.data.status == "success") {
                    this.isBusy = false;
                    this.$emit("refresh");
                    this.$snotify.success("Remove successfully");
                  }
                })
                .catch(() => {
                  this.isBusy = false;
                  this.$snotify.error("Something Went Wrong");
                });
          }
        }
      });
    },

    createOrUpdate() {
      this.group.supervisor_ids = [...this.group.supervisor_ids, ...this.selectedSupervisor]
      this.$v.group.$touch();
      if (this.$v.group.$error) {
        setTimeout(() => {
          this.$v.group.$reset();
        }, 3000);
      } else {
        this.updateGroup();
      }
    },

    updateGroup() {
      this.isBusy = true;
      group
          .update(this.group.id, this.group)
          .then((response) => {
            if(response.data.status == "success") {
              this.isBusy = false;
              this.$emit("refresh");
              this.closeDialog();
              this.$snotify.success("Information added");
            }
          })
          .catch(() => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    resetForm() {
      this.$v.group.$reset();
      this.searchFirstPresident = null;
      this.searchSecondPresident = null;
      this.searchSupervisor = null;
      this.selectedPresidentOne = null;
      this.selectedPresidentTwo = null;
      this.selectedSupervisor = [];
      this.firstPresidentUsers = [];
      this.secondPresidentUsers = [];
      this.supervisorUsers = [];
      this.isBusy = false;
      this.isUserLoading = false;
      this.group = {
        president_one_id: '',
        president_two_id: '',
        supervisor_ids: [],
        president_one: '',
        president_two: '',
        supervisors: [],
        count_supervisors: 3
      };

    },

    searchMember(val, type = 'first') {
      let id = [];
      let types = [];
      if(this.is_president) {
        types = ['student'];
        id = [this.group.president_one_id, this.group.president_two];
      }
      if(!this.is_president) {
        types = ['staff'];
        id = this.group.supervisor_ids;
      }

      let data = {
        val: val,
        id: id,
        types: types
      };
      this.isUserLoading = true;

      if(type == 'first') {
        group.getUsersForMember(this.group.id, data).then((response) => {
          if(response.data.users) {
            this.firstPresidentUsers = response.data.users;
          } else {
            this.firstPresidentUsers = [];
          }
        }).catch(() => {}).finally(() => (this.isUserLoading = false));
      }

      if(type == 'second') {
        group.getUsersForMember(this.group.id, data).then((response) => {
          if(response.data.users) {
            this.secondPresidentUsers = response.data.users;
          } else {
            this.secondPresidentUsers = [];
          }
        }).catch(() => {}).finally(() => (this.isUserLoading = false));
      }

      if(type == 'supervisor') {
        group.getUsersForMember(this.group.id, data).then((response) => {
          if(response.data.users) {
            this.supervisorUsers = response.data.users;
          } else {
            this.supervisorUsers = [];
          }
        }).catch(() => {}).finally(() => (this.isUserLoading = false));
      }
    },
  },

  watch:{

    searchFirstPresident(val) {
      if(val) {
        this.searchMember(val, )
      }
    },
    searchSecondPresident(val) {
      if(val) {
        this.searchMember(val, 'second')
      }
    },
    searchSupervisor(val) {
      if(val) {
        this.searchMember(val, 'supervisor')
      }
    },
  }
}
</script>
