<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>{{ groupName }}</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ {{ groupName }}
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('user-group', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addUserGroup">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>
                                        Add group
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="6">
                                    <v-text-field class="form-control" v-model="search.title"
                                        label="Search by title" outlined clearable
                                        v-on:keyup.enter="getUserGroups()" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-btn :loading="loading" @click.prevent="searchUserGroups()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Clubs president</th>
                                        <th class="px-3 wrap-column">Supervisors</th>
                                        <th class="px-3 wrap-column">Description</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="userGroups.length > 0" v-for="(item, index) of userGroups" :key="index">
                                        <td class="px-3 wrap-column">
                                            <a href="#" @click="viewUsers(item.id)">{{ item.title }}</a>
                                        </td>
                                        <td class="px-3 wrap-column">
                                          <p><b>President one: </b> {{item.president_one || 'N/A'}}</p>
                                          <p> <b>President two: </b> {{item.president_two || 'N/A'}}</p>
                                        </td>
                                        <td class="px-3 wrap-column">
                                          <ul class="mb-3" v-if="item && item.supervisors.length > 0">
                                            <li class="mx-10" v-for="(supervisor, index) in item.supervisors" :key="index">
                                              <v-chip class="my-1" close small @click:close="removeSupervisor(index)">{{supervisor || 'N/A'}}</v-chip>
                                            </li>
                                          </ul>
                                          <ul class="mb-3" v-if="item && item.supervisors.length == 0">
                                            <span class="text-center">No one supervisor is selected</span>
                                          </ul>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div v-if="item.description" v-html="item.description"></div>
                                            <div v-else>NA</div>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('group-member', 'list')">
                                                        <a href="#" class="navi-link" @click="viewUsers(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="fas fa-users"></i>
                                                            </span>
                                                            <span class="navi-text"> Member </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('group-member', 'list')">
                                                        <a href="#" class="navi-link" @click="assignLeaderAndRepresented(item, true)">
                                                            <span class="navi-icon">
                                                                <i class="fas fa-user"></i>
                                                            </span>
                                                            <span class="navi-text"> Assign president </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('group-member', 'list')">
                                                        <a href="#" class="navi-link" @click="assignLeaderAndRepresented(item, false)">
                                                            <span class="navi-icon">
                                                                <i class="fas fa-user"></i>
                                                            </span>
                                                            <span class="navi-text"> Assign supervisor </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user-group', 'edit')">
                                                        <a href="#" class="navi-link" @click="editUserGroup(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user-group', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteUserGroup(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="userGroups.length == 0">
                                        <td class="text-center  px-3" colspan="3">
                                          <strong>No data available to display.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination
                                v-show="userGroups.length > 0"
                                @input="getUserGroups"
                                class="pull-right mt-7"
                                v-model="page"
                                :total-rows="total"
                                :per-page="perPage"
                                first-number
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <assign-leader ref="assign-leader" @refresh="getUserGroups"></assign-leader>
    </v-app>
</template>

<script>
import AssignLeader from "@/view/pages/user/group/AssignLeader.vue";
import UserGroupService from "@/core/services/user/user-group/UserGroupService";

const userGroup = new UserGroupService();

export default {
  name:"group",
  components: {AssignLeader},
  data() {
      return {
          search: {
              title: '',
          },
          loading: false,
          userGroups: [],
          page: null,
          perPage: null,
          total: null,
      }
  },
  computed: {
    routeTab() {
      return {
        title: this.groupName,
      };
    },
  },
  methods: {
    assignLeaderAndRepresented(userGroup, isPresident)
    {
      this.$refs['assign-leader'].openDialog(userGroup, isPresident)
    },
    searchUserGroups(){
        this.page = 1;
        this.getUserGroups();
    },

    getUserGroups() {
        this.loading = true;
        userGroup
            .paginate(this.search, this.page)
            .then(response => {
                this.userGroups = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
            });
    },

    addUserGroup() {
        this.$router.push(
            {
                name: 'user-group-create'
            }
        );
    },

    editUserGroup(id) {
        this.$router.push(
            {
                name: 'user-group-edit',
                params:{
                    userGroupId: id
                }
            }
        );
    },

    deleteUserGroup(userGroupId){
        this.$confirm({
            message: `Are you sure you want to delete?`,
            button: {
                no: "No",
                yes: "Yes"
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
                if (confirm) {
                    userGroup
                    .delete(userGroupId)
                    .then(response => {
                        this.$snotify.success('Successfully deleted')
                        this.getUserGroups();
                    })
                    .catch(error => {
                    });
                }
            }
        });
    },

    viewUsers(userGroupId){
        this.$router.push(
            {
                name: 'user-group-member',
                params:{
                    userGroupId: userGroupId
                }
            }
        );
    },

    removeSupervisor(index) {

      this.$confirm({
        message: `Are you sure you want to delete?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.group.supervisor_ids.splice(index, 1);
            this.group.supervisors.splice(index, 1);
            this.isBusy = true;
            userGroup
                .update(this.group.id, this.group)
                .then((response) => {
                  if(response.data.status == "success") {
                    this.isBusy = false;
                    this.$emit("refresh");
                    this.$snotify.success("Remove successfully");
                  }
                })
                .catch(() => {
                  this.isBusy = false;
                  this.$snotify.error("Something Went Wrong");
                });
          }
        }
      });
    },
  },
  mounted() {
      this.getUserGroups();
  }
}
</script>
